// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Grid } from 'components/core/layout';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import BackgroundImage from 'components/core/images/background-image';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  backgroundFluidImage: Object,
  children: Node,
  title: ?string
|};

/**
 * `Section` styled component.
 */

const Section = styled.header`
  position: relative;

  ${media.min('md')`
    align-items: center;
    display: flex;
    justify-content: center;
  `}

  ${media.min('lg')`
    min-height: 100vh;
  `}
`;

/**
 * `StyledGrid` styled component.
 */

const StyledGrid = styled(Grid)`
  padding: ${units(10)} 0;

  ${media.min('md')`
    padding: ${units(18)} 0;
  `}
`;

/**
 * `StyledBackgroundImage` styled component.
 */

const StyledBackgroundImage = styled(BackgroundImage)`
  &,
  &::after,
  &::before {
    background-position: bottom center !important;
  }
`;

/**
 * `Header` component.
 */

function Header(props: Props): Node {
  const { backgroundFluidImage, children, title } = props;

  return (
    <Section>
      <StyledBackgroundImage fluid={backgroundFluidImage} />

      <Container>
        <StyledGrid>
          <Box
            gridColumn={'1 / -1'}
            gridColumnLg={'2 / -2'}
          >
            {title && (
              <Type.H1
                color={color('secondary')}
                marginBottom={units(9)}
                textAlign={'center'}
              >
                <RawHtml>
                  {title}
                </RawHtml>
              </Type.H1>
            )}

            {children}
          </Box>
        </StyledGrid>
      </Container>
    </Section>
  );
}

/**
 * Export `Header` component.
 */

export default Header;
