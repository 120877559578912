// @flow

/**
 * Module dependencies.
 */

import { Card } from 'components/core/card';
import type { TemplateProps } from 'types/template';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { graphql, useStaticQuery } from 'gatsby';
import Button from 'components/core/button';
import FooterIconsSection from 'components/footer/footer-icons-section';
import Header from 'components/contacts/header';
import PageContainer from 'components/page-container';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import SocialNetworks from 'components/contacts/social-networks';
import styled from 'styled-components';

/**
 * Contacts page query.
 */

const contactsPageQuery = graphql`
  query {
    data: allSeegnoCmsContactsPage {
      nodes {
        content {
          backgroundImage {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
          footerSection {
            buttonLabel
            buttonUrl
            leftIcon {
              file {
                publicURL
              }
            }
            leftText
            rightIcon {
              file {
                publicURL
              }
            }
            rightText
          }
        }
      }
    }
  }
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-gap: ${units(5)};

  ${media.min('md')`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H6)`
  color: ${color('secondary')};
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.H5).attrs({ as: 'div' })`
  flex: 1;
  margin-bottom: ${units(6)};

  ${media.min('md')`
    min-height: ${units(23)};
  `}
`;

/**
 * `StyledCard` styled component.
 */

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 400px;
  padding: ${units(3)};
  width: 100%;
`;

/**
 * `FollowLabel` styled component.
 */

const FollowLabel = styled(Type.Paragraph)`
  color: ${color('grey400')};
  display: block;
  margin-bottom: ${units(2)};
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  align-self: flex-end;
  width: 100%;
`;

/**
 * `Contacts` template.
 */

const Contacts = ({ pageContext }: TemplateProps): Node => {
  const content = pageContext?.content;
  const data = useStaticQuery(contactsPageQuery);
  const dataContent = data?.data?.nodes[0]?.content;
  const metatags = pageContext?.metatags;

  return (
    <PageContainer
      footerElement={<FooterIconsSection {...dataContent?.footerSection} />}
      metatags={metatags}
    >
      <Header
        backgroundFluidImage={dataContent?.backgroundImage?.file?.childImageSharp?.fluid}
        title={content?.title}
      >
        <Grid>
          <StyledCard>
            <div>
              <Title marginBottom={units(3)}>
                {content?.support?.title}
              </Title>

              <Description>
                <RawHtml>
                  {content?.support?.description}
                </RawHtml>
              </Description>
            </div>

            <StyledButton href={content?.support?.buttonUrl}>
              {content?.support?.buttonLabel}
            </StyledButton>
          </StyledCard>

          <StyledCard>
            <Title marginBottom={units(2)}>
              {content?.community?.title}
            </Title>

            <FollowLabel>
              {content?.community?.socialFollow}
            </FollowLabel>

            <SocialNetworks />
          </StyledCard>

          <StyledCard>
            <Title marginBottom={units(3)}>
              {content?.general?.title}
            </Title>

            <Description>
              <RawHtml>
                {content?.general?.description}
              </RawHtml>
            </Description>

            <StyledButton href={content?.general?.buttonUrl}>
              {content?.general?.buttonLabel}
            </StyledButton>
          </StyledCard>
        </Grid>
      </Header>
    </PageContainer>
  );
};

/**
 * Export `Contacts` template
 */

export default Contacts;
