// @flow

/**
 * Module dependencies.
 */

import { Type } from '@seegno-labs/react-components/typography';
import { graphql, useStaticQuery } from 'gatsby';
import { keys, map, pickBy } from 'lodash';
import { units } from '@seegno-labs/react-components/styles';
import IconButton from 'components/core/button/icon-button';
import React, { type Node, useMemo } from 'react';
import facebookIcon from 'assets/svg/social/facebook.svg';
import linkedinIcon from 'assets/svg/social/linkedin.svg';
import styled from 'styled-components';
import twitterIcon from 'assets/svg/social/twitter.svg';

/**
 * Social icons.
 */

const socialIcons = {
  facebookUrl: facebookIcon,
  linkedinUrl: linkedinIcon,
  twitterUrl: twitterIcon
};

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li`
  margin-bottom: ${units(1)};
  text-transform: capitalize;
`;

/**
 * Social links query.
 */

const socialLinksQuery = graphql`
  query {
    socialLinks: allSeegnoCmsSetting {
      nodes {
        code
        value
      }
    }
  }
`;

/**
 * `SocialLinks` component.
 */

const SocialLinks = (): Node => {
  const { socialLinks } = useStaticQuery(socialLinksQuery);
  const socialIconsKeys = keys(socialIcons);
  const links = useMemo(() => pickBy(
    socialLinks.nodes,
    ({ code }) => socialIconsKeys.includes(code)
  ), [socialIconsKeys, socialLinks.nodes]);

  return (
    <ul>
      {map(links, ({ code, value }) => value && (
        <ListItem key={code}>
          <IconButton
            href={value}
            icon={socialIcons[code]}
            rel={'noopener'}
            size={'small'}
            target={'_blank'}
          >
            <Type.Paragraph>
              {code.replace('Url', '')}
            </Type.Paragraph>
          </IconButton>
        </ListItem>
      ))}
    </ul>
  );
};

/**
 * Export `SocialLinks` component.
 */

export default SocialLinks;
